<template>
  <section align-center-container>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'AlignCenterContainer',
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[align-center-container] { .flex(); .flex-dc(); .justify-center(); .items-center(); .p(16, 16, 10vh); .tc(); }
</style>
