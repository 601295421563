<template>
  <ErrorContainer>
    <h1>PROMOTION!!</h1>
    <template>Sorry, there's nothing at this address.</template>
  </ErrorContainer>
</template>

<script>
import ErrorContainer from '@/views/components/promotion/ErorrContainer.vue';

export default {
  name: 'NotFound',
  components: { ErrorContainer }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[not-found] {}
</style>
