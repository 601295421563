<template>
  <AlignCenterContainer error-container>
    <div>
      <img src="/img/restrict_country_daniel.png" alt="error">
      <h3><slot /></h3>
    </div>
  </AlignCenterContainer>
</template>

<script>
import AlignCenterContainer from '@/views/components/pages/promotion/AlingCenterContainer.vue';

export default {
  name: 'ErrorContainer',
  components: { AlignCenterContainer }
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[error-container] { .fs(16); .h(100vh);
  img { .w(9em); }
  h3 { .fs(1.5em); .medium(); .mt(1em); .c(@c-mediumGrey); }
}
</style>
